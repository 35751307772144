
.CodeMirror {
  border: rgb(189, 189, 189) solid 1px;
  border-radius: 4px;
  height: auto !important;
  min-height: 85px;
  width: auto !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.CodeMirror-scroll {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  width: auto !important;
  margin-right: 0px !important
}

