

.spin{
    width: 100px;
    
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    display: inline-block;
    left: 50%;

    background-repeat: no-repeat;
    background-size: contain;

    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}

.spin img{
  height: 100px;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.customspin{
    width: 100px;
    
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    display: inline-block;
    left: 50%;

    background-repeat: no-repeat;
    background-size: contain;

    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}

.customspin img{
  height: 100px;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}